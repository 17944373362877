import React from 'react';
import { graphql } from 'gatsby';
import ItemsList from '../components/ItemsList';
import CategoryFilterAll from '../components/CategoryFilterAll';
// import Nav from '../components/Nav';
import Logo from '../components/Logo';

export default function ItemsPage ({ data, pageContext }) {
  const items = data.items.nodes;
  return (
    <>
      <Logo />
      <br />
      <CategoryFilterAll />
      <h1 class="center">All Items</h1>
      <br />
      <br />
      <ItemsList items={items} />
    </>
  );
}

export const query = graphql`
  query ItemsQuery($categoryRegex: String) {
  items: allSanityItems (
    filter: { category: { elemMatch: { name: { regex: $categoryRegex } } } }
    sort: { fields: _createdAt, order: ASC } 
  ) {
    nodes {
      name
      id
      price
      description
      _createdAt
      slug {
        current
      }
      category {
        id
        name
      }
    }
  }
}
`;

