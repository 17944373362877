// import React from 'react';
// import { Link } from 'gatsby';
// import Img from 'gatsby-image';
// import PizzasPage from '../pages/pizzas';

// function SingleItem ({ items }) {
//   return (
//     <div>
//       <Link to={`/items/${items.slug.current}`}>
//         <h2>
//           <span className="mark">{items.name}</span>
//         </h2>
//         <p>{items.category.map((category) => category.name).join(', ')}</p>
//         <Img fluid={items.image.asset.fluid} alt={items.name} />
//       </Link>
//     </div>
//   );
// }

// export default function ItemsList ({ items }) {
//   return (
//     <div>
//       {items.map((items) => (
//         <SingleItem key={items.id} items={items} />
//       ))}
//     </div>
//   );
// }

import React from 'react';
// import { Link } from 'gatsby';
// import Img from 'gatsby-image';
import styled from 'styled-components';
import formatMoney from '../utils/formatMoney';

const ItemsGridStyles = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 4rem;
  grid-auto-rows: auto auto 50px;
  @media (max-width: 709px) {
    gap: 2rem;
  }
  @media (max-width: 1024px) {
    gap: 3rem;
  }
`;

const ItemsStyles = styled.div`
  display: grid;
  /* Take your row sizing not from the pizzaStyles div, but from the  PizzaGridStyles grid */
  @supports not (grid-template-rows: subgrid) {
    --rows: auto auto 1fr;
  }
  grid-template-rows: var(--rows, subgrid);
  grid-row: span 3;
  grid-gap: 1rem;
  h2,
  p {
    margin: 0;
  }
`;

// function SingleItem ({ items }) {
//   return (
//     <ItemsStyles>
//       <Link to={`/items/${items.slug.current}`}>
//         <h2>
//           <span className="mark">{items.name}</span>
//         </h2>
//       </Link>
//       <p>{items.category.map((category) => category.name).join(', ')}</p>
//       <Img fluid={items.image.asset.fluid} alt={items.name} />
//     </ItemsStyles>
//   );
// }


const ParentStyles = styled.div`
  display: flex;
  justify-content: space-between;
  .parent:first-of-type > div:last-child { order: -1; }
`;

const ChildPriceStyles = styled.div`
  float: right;
`;

const DividerStyles = styled.div`
  flex-grow: 1;
  border-bottom: 1px dotted black;
  margin: 5px
`;


function SingleItem ({ items }) {
  return (
    <ItemsStyles>
      {/* <Img fluid={items.image.asset.fluid} alt={items.name} class={items.id} />
      <br /> */}
      <ParentStyles>
        <div>{items.name}</div>
        <DividerStyles></DividerStyles>
        <ChildPriceStyles><p class={items.slug.current} >{formatMoney(items.price)}</p></ChildPriceStyles>
      </ParentStyles>
      <h5>
        <span>{items.description}</span>
      </h5>
    </ItemsStyles >
  );
}

export default function ItemsList ({ items }) {
  return (
    <ItemsGridStyles>
      {items.map((items) => (
        <SingleItem key={items.id} items={items} />
      ))}
    </ItemsGridStyles>
  );
}